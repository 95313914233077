<div class="side-nav">
  <div class="item logo">
    <div class="item-icon">
      <img src="assets/c-logo.svg?v=1" class="logo-a" alt="logo">
    </div>
  </div>

  <ng-container *ngIf="currentUser">
    <a class="item d-block"
       routerLink="/dashboard"
       routerLinkActive="active"
       (click)="resetSearch()"
       smTooltip="DASHBOARD"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right"
    >
      <div class="item-icon">
        <i class="al-icon al-ico-home al-color blue-300"></i>
      </div>
      <div class="caption">dashboard</div>
    </a>

    <a class="item d-block"
       smTooltip="PROJECTS"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right"
       routerLink="/projects"
       routerLinkActive="active"
    >
       <div class="item-icon">
         <i class="al-icon al-ico-projects al-color blue-300"></i>
      </div>
      <div class="caption">projects</div>
    </a>
    <a class="item d-block"
       routerLink="/datasets"
       routerLinkActive="active"
       smTooltip="DATASETS"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
      <div class="item-icon">
        <i class="al-icon al-ico-datasets al-color blue-300"></i>
      </div>
      <div class="caption">datasets</div>
    </a>
    <a class="item d-block"
       routerLink="/pipelines"
       routerLinkActive="active"
       smTooltip="PIPELINES"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
      <div class="item-icon">
        <i class="al-icon al-ico-pipelines al-color blue-300"></i>
      </div>
      <div class="caption">pipelines</div>
    </a>
    <a class="item d-block"
       routerLink="/workers-and-queues"
       routerLinkActive="active"
       smTooltip="WORKERS & QUEUES"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
      <div class="item-icon">
        <i class="al-icon al-ico-queues al-color blue-300"></i>
      </div>
      <div class="caption">workers & queues</div>
    </a>

  </ng-container>
  <div *ngIf="currentUser" class="account">
    <a *ngIf="environment.whiteLabelLink" class="item d-block" target="_blank"
       [smTooltip]="environment.whiteLabelLink.tooltip"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right"
       [href]="environment.whiteLabelLink.link">
      <div class="item-icon"
           [style.background-image]="'url(' + environment.whiteLabelLink.logo + ')'"
           style="width: 64px; height: 64px"
      >
      </div>
      <div class="caption">Ignite</div>
    </a>
    <a *ngIf="environment.slackLink" class="item d-block" [href]="environment.slackLink"
       target="_blank"
       smTooltip="Community support on Slack" [matTooltipShowDelay]="0" matTooltipPosition="right">
      <div class="item-icon">
        <i class="al-icon al-ico-slack md al-color blue-300"></i>
      </div>
      <div class="caption">Slack</div>
    </a>
  </div>
</div>
